.capa{
  height:100%;
  display:'flex'; 
  flex-direction:"column"; 
} 
@media screen and (max-width:600px){
  .capa{
    justify-content:'center'; 
    align-items:"center";
  } 
} 