@import 'handsontable/dist/handsontable.full.css';
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-grids/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-spreadsheet/styles/material.css';


@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');


:root {
 
    --themeColor: white;
    /* --backGroundImage: none;
    --backGroundColor: #1a1a1a;
    --backgroundRepeat: no-repeat;
    --backgroundSize: cover;
    --backgroundPosition: center; */


    /* background-color: #000000; */
    background-color: var(--backGroundColor);

    background-image: var(--backGroundImage);
    background-size: cover;
    background-position: center;

    
    

    /* background-repeat: var(--backgroundRepeat); */
    /* background-size: var(--backgroundSize);
    background-position: var(--backgroundPosition); */

    font-size: 1rem;  

    /* width:100%;
    height:100%; */

     /* zoom: 0.8;

    transform:translateY(6%); */
}



.img-anim-capa-geral{
  background-color: transparent;
  pointer-events: none;
  z-index: 100;
  overflow: hidden;
  position: absolute;
  right: 300px;
  margin-top: 200px;
  height: 60vh;
  /* animation: bounce 3s ease-in-out infinite; */
  /* transform-origin: 50% 0%; */
  trasição: all 0.5s ease-in-out;
}


.img-anim-capa-desafio-do-dia{
  background-color: transparent;
  pointer-events: none;
  z-index: 100;
  overflow: hidden;
  position: absolute;
  right: 300px;
  height: 90vh;
  animation: bounce 3s ease-in-out infinite;
  transform-origin: 50% 0%;
  trasição: all 0.5s ease-in-out;
}

.img-anim-capa-avaliacao-final{
  background-color: transparent;
  pointer-events: none;
  z-index: 100;
  overflow: hidden;
  position: absolute;
  right: 250px;
  height: 80vh;
  padding-top: 30px;
  transform-origin: 50% 0%;
  trasição: all 0.5s ease-in-out;
}

.img-anim-capa-hora-do-livro{
  background-color: transparent;
  pointer-events: none;
  z-index: 100;
  overflow: hidden;
  position: absolute;
  right: 200px;
  height: 80vh;
  padding-top: 30px;
  transform-origin: 50% 0%;
  trasição: all 0.5s ease-in-out;
}

@keyframes bounce {

  0% {
    transform: translateY(-30px)  rotate(-12deg);
  }
  50% {
    transform: translateY(-90px) rotate(6deg);
  }
  100% {
    transform: translateY(-30px)  rotate(-12deg);
  }
}


@media screen and (max-width: 1750px) {
  .img-anim-capa-geral{
    top: 30px;
    right: 200px;
    height: 60vh;
  }
  .img-anim-capa-desafio-do-dia{
    top: 30px;
    right: 200px;
    height: 80vh;
  }
  .img-anim-capa-avaliacao-final{
    top: 30px;
    right: 200px;
    height: 80vh;
  }
  .img-anim-capa-hora-do-livro{
    top: 30px;
    right: 100px;
    height: 80vh;
  }
}

@media screen and (max-width: 1350px) {
  .img-anim-capa-geral{
    top: 30px;
    right: 200px;
    height: 50vh;
  }
  .img-anim-capa-desafio-do-dia{
    top: 30px;
    right: 200px;
    /* height: 70vh; */
  }
  .img-anim-capa-avaliacao-final{
    top: 30px;
    right: 100px;
    /* height: 80vh; */
  }
  .img-anim-capa-hora-do-livro{
    top: 30px;
    right: 100px;
    /* height: 80vh; */
  }
}


*{
    /* font-family: 'Open Sans', sans-serif !important; */
    font-family: 'Open Sans', sans-serif ;

    box-sizing: border-box;
    margin:0;
    padding: 0;
    max-width: 100%;
    font-size: 1rem;
    text-align: left;
      /* border: 1px solid rgba(255,0,0,0.4); */


}
p{

}
*:link{
    /* color: var(--themeColor); */
    color: var(--backGroundColor);
    /* font-weight: 800; */
    background-color: transparent;
    text-decoration: none;
}
*:visited{
    color: var(--backGroundColor);
    /* font-weight: 800; */
    background-color: transparent;
    text-decoration: none;
}

li{
  margin-left: 20px;
  margin-bottom: 10px;
}


textarea:focus, input:focus, select:focus {
    outline: 0;
}
body{
    overflow: hidden;
}
.App{
    /* max-width:100vw; */
    overflow: auto;
    /* min-width: 100vw; */
  
}

/* Safari syntax */
:-webkit-full-screen {
  background-color:var(--backGroundColor);
}

/* IE11 */
:-ms-fullscreen {
  background-color: var(--backGroundColor);
}

/* Standard syntax */
:fullscreen {
  background-color:var(--backGroundColor);
}

/* Style the button */
button {
  padding: 20px;
  font-size: 20px;
}
img:hover {
  cursor:zoom-in;
}

.error-modal{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30vw;
  height: 30vh;
  transform: translate(-50%, -50%);
  min-width: 600px;
  min-height: 500px;
  padding:50px;

  background-color: rgba(0,0,0,0.04);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
  font-size: 4em;
  gap: 50px;

  & h1{
    text-align: center;
    font-size: 1.5em;
    color: rgba(0,0,0,.6);
  }
}


.image-container{
  width: 160px;
}




