
  .download_space{
    /* border: 2px dashed var(--backGroundColor  ); */
    border-radius: 16px;
    
    height: auto ;
    /* margin-left: 20px; */
    /* padding: 12px 12px 12px 18px; */
    color: var(--backGroundColor);
  }
  @media screen and (max-height: 720px) {
    .download_space a{
      font-size: small;
      line-height: 1.6;
      
    }

  }
  
  
  
  .m-menu.-active .m-menu__list {
    transform: scale(1);
    position: absolute;
    margin-left:60px;

  }
  .m-menu__list {
    position: absolute;
    bottom: 100px;

    white-space: normal;
    z-index: 101;
    padding: 10px;
    max-width: 55vh;
    border-radius: 20px;
    background-color: #fff;
    transform: scale(0);
    transform-origin:  10% 100%;
    /* box-shadow: 10px 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22); */
  }

  .acesse-popup-text{

  margin-top: 20px;
  min-height:80px;
  user-select: none;   
  color:rgb(40, 40, 40);
  padding-right: 40px; 
  margin-left: 30px;
  position: relative;


}

.acesse-popup-text h1{
    /* font-weight: 300;   */
    font-weight: 400;
    padding-bottom: 20px;
    font-size: 18px;  
  
}

.acesseIcon{

  cursor: pointer;

  
}

.acesseIcon {
  animation: blinker 1.6s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0; 
  }
}
