@import '../../App.css';

@import url('https://fonts.cdnfonts.com/css/aller-display');

.titleEnsinaMais{
  font-family: 'Aller Display', sans-serif !important;
  /* zoom: 1; */
  /* font-size: 1.2em;
  font-size: 2.2vmax; */


  font-size: 1.3em;
  text-shadow: 1px 1px #558abb;
  color: #fff;
  white-space: pre-line;
  border: 2px solid #000;
  background-color: var(--themeColor);
  padding-left: 10px; 

  min-height: 30px;
  padding-right: 70px;
  position: relative;
  white-space: nowrap;
  height: 50px;
  /* align-items: center; */
  /* width: fit-content; */
  /* margin-left: auto; */
  /* align-text: center; */
  display: flex;
  justify-content: start;
  align-items: center;
  width: fit-content;
  transition: 0.5s;
  margin-top: -40px;
  margin-bottom: -10px;
  box-shadow: 10px 10px 1px 0px rgba(0,0,0,0.3);

}

@media (max-width: 968px) {
  .titleEnsinaMais{
    font-size: 1.8vmax;
  }
  
}

@media (max-height: 768px) {
  .titleEnsinaMais{
    margin-top: -50px;
  }
  
}


.titleEnsinaMais img{
  position: absolute;
  width: 62px;
  right: 0;
  top: -20px;
  cursor: default;
 
  /* height: 10vw; */

}