
.teste-conhecimentos{
    box-sizing: border-box;
    min-width:100%;
    min-height: 100vh;  
    display:flex;
    justify-content: center;
    align-items: center; 
    background-repeat: no-repeat; 
    background-size: cover; 
    z-index: 1;
      overflow: hidden;
    /* background-image: url('bg.jpg');  */
    transition: 0.6s;

}

/* @import '../../App.css';
.btn-comecar{
    display:flex;
    justify-content: center;
    align-items: center;
    font-size:22px;
    height: 60px; 
    padding:7px 25px;
    border-radius: 30px; 
    background:var(--themeColor);
    border:1px solid var(--themeColor);
    z-index:1000; 
    color:white;
    transition:all 0.3s;
  } */
  
  .btn-comecar:hover{
    background:none;
    color:var(--themeColor); 
    padding:7px 40px;
    transform: translateX(-16px);
    border:1px solid var(--themeColor); 
  }
  .tela-inicial-right-mao-na-massa{
    padding-bottom: 5px;
  }
 








/* .description{
    width: 90%;
    height:70%;
    font-size: 0.7em;
    margin-top:10%; 
    user-select: none;
    padding: 10px;    
    font-size: 15px;
    font-weight: 80;
    color:grey;
    user-select: none;
    line-height: 2;
    overflow: auto;
    transition: 0.6s
} */


#hide{
    position:absolute;
    top:50px;
    left:50px;
    width:300px;
    height:200px;
    z-index:99999;
    display:none;
    }

    .lesson-description-text{
       
        margin-top: 20px;
        min-height:80px;
        user-select: none;   
        font-size: 35px; 
  color:rgb(40, 40, 40);
        user-select: none;

        text-align: justify
    }
    .tela-final{
      width:87%;
      margin:auto;
      height:800px;  
      display:flex;
      flex-direction: column;
      overflow: hidden;

  
  }
  .tela-final-mensagem{
    margin-top: 75px;
  }
  .tela-final button{
    margin-top: 50px;
    font-size: 20px; 
    padding:7px 20px;
    cursor:pointer;
    background:var(--themeColor); 
    border-radius: 30px;
    color:white;
  }
  .btn-comecar-left-final:hover{
    transform:translateX(-15px)

  }
  .btn-fullscreen{
    display:flex;
    justify-content:center;
    align-items:center;
    border:2px solid rgba(210,210,210,0.35);
    border-radius:50%;
    width:60px;
    height:60px;
    position: absolute;
    left: 50px;
    bottom: 40px;
  }
  @media screen and (max-width:1300px){
    .btn-comecar-left{
      margin-left:40px;      
    }
    .btn-fullscreen{
      left: 20px;
      bottom: 20px;
    }

}
  @media screen and (max-width:600px){
      .tela-final{
          align-items: center;
      }
      .tela-final button{
          margin-top: 75px;
          margin-left: 0;          
      }
      .mobile{
        display:flex;
        justify-content: center;
      }  
      .section-left-2{
        padding-left:0px;      
      }
      .btn-fullscreen{
        display: none;
      }
  }

.section-right-mm {
    width: 100%;
    height: 84%;
    overflow: auto;
    margin: 0 auto;
}




