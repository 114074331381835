.pense-responda {
  box-sizing: border-box;
  min-width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
  transition: 0.6s;
  /* overflow: hidden; */

  /* background-image: url('https://img.genial.ly/5fc6cd5b3166f50f91c09b4c/a9adac76-0b67-4862-a9a8-83b50b60f987.jpeg') */
}

@import '../../App.css';

.imgbg-mao-na-massa {
  position: absolute;
  max-height: 80%;
  padding-top: 90px;
  margin-right: 150px;

}

.imgbg {
  position: absolute;
  max-height: 80vh;
  padding-top: 30px;

  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.imgbg-mao-na-massa img {
  transform: scale(1.2);
}

.pai-img {


  /* overflow: hidden; */

}

/* .imgbg_mm{
  z-index: 1;
  display: flex;
  position: absolute;
  width: 90vh;
  right: 250px;
  top: 90px} */


main {
  width: 84%;
  display: flex;

  border-radius: 40px;
  overflow: hidden;
  transition: 0.6s;

}

/* //////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////////////// */


@keyframes opacityshow {
  0% {
    opacity: 0;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes opacityhide {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes hide {
  0% {
    width: 50%;
    opacity: 1;
  }

  100% {
    width: 0%;
    padding-left: 0px;
  }
}

@keyframes show {
  0% {
    width: 50%;
  }

  100% {
    width: 100%;
    box-shadow: none;
    padding-left: 0px;
    border: 1px black;
    ;
  }
}

@keyframes reversehide {
  0% {
    width: 0%;
  }

  100% {
    width: 50%;
    padding-left: 30px;
  }
}

@keyframes reverseshow {
  0% {
    width: 100%;
  }

  100% {
    width: 50%;
    padding-left: 30px
  }
}

@keyframes fillspace {
  0% {
    width: 85%;
    height: 85vh;
  }

  100% {
    width: 94%;
    height: 90vh;
  }
}

@keyframes unfillspace {
  100% {
    width: 85%;
    height: 85vh;
  }

  0% {
    width: 94%;
    height: 85vh;
  }
}

@keyframes goup {
  100% {
    top: 46%;
  }

  0% {
    top: 85%;
  }
}

@keyframes godown {
  0% {
    top: 46%;
  }

  100% {
    top: 90%;
  }
}

.course-name {
  color: rgb(40, 40, 40);
  margin: 30px 60px 40px;
  font-size: 1.8em;
  transition: 1s;
  white-space: nowrap;

}



.section-left {
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.74);
  overflow: hidden;
  z-index: 0;
  transition: 0.6s;
  backdrop-filter: blur(8px) saturate(170%);
  width: 50%;
  height: 100%;
  padding: 30px;

}

.section-left-1 {
  width: 90%;
  height: 85%;
  display: flex;
  flex-direction: column;
  padding-left: 45px;
  padding-top: 43px;
  padding-left: 50px;


}

.section-left-2 {

  display: flex;
  align-items: center;
  padding-left: 15px;

}

.all-itens {
  position: relative;


}


.section-right {
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.74);
  /* opacity: 0.97; */
  /* box-shadow: 25px 0px 25px 0px; */
  overflow-x: hidden;
  z-index: 0;
  transition: 0.6s;
  backdrop-filter: blur(8px) saturate(170%);
  width: 50%;
  max-height: 100%;


}

.section-right-mode0 {


  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 30px;
  background: rgba(255, 255, 255, 0.8);
  z-index: 0;
  transition: 0.6s;
  backdrop-filter: blur(13px) saturate(140%);
  width: 50%;

}

.centro {
  position: absolute;
  left: calc(50% - 30px);
  width: 60px;
  height: 84.65vh;
  border-radius: 30px;
  background-color: rgba(240, 240, 240, 0.2);
  z-index: 1;
  transition: 0s;
}

.centro2 {
  position: absolute;
  left: calc(50% - 2px);
  width: 4px;
  height: 84.65vh;
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.2);
  transition: 0s;

}


button {
  font-size: 22px;
  /* font-weight: 300; */
  padding: 7px 20px;
  cursor: pointer;
  background: var(--themeColor);
  border-radius: 30px;
  color: white;
}

.btn-comecar-left {

  align-items: center;
  font-size: 22px;
  padding: 7px 25px;
  border-radius: 30px;
  left: 10%;
  bottom: 10%;
  background: var(--themeColor);
  border: 1px solid var(--themeColor);
  z-index: 1000;
  color: white;
  transition: all 0.3s;
  bottom: 100px;
  font-weight: 700;

}

.btn-comecar-left:hover {
  background: none;
  color: var(--themeColor);
  padding: 7px 40px;
  transform: translateX(-16px);
  border: 1px solid var(--themeColor);
}

.btn-comecar-left-final {

  align-items: center;
  font-size: 22px;
  padding: 7px 25px;
  border-radius: 30px;
  left: 10%;
  bottom: 10%;
  background: var(--themeColor);
  border: 1px solid var(--themeColor);
  z-index: 1000;
  color: white;
  transition: all 0.3s;
  bottom: 100px;
  font-weight: 700;

}

.btn-comecar-left-final:hover {
  background: none;
  color: var(--themeColor);
  padding: 7px 40px;
  /* transform: translateX(-16px); */
  border: 1px solid var(--themeColor);
}

.btn-comecar-right {
  width: fit-content;
  font-size: 22px;
  padding: 10px 25px;
  border-radius: 30px;
  background: var(--themeColor);
  border: 1px solid var(--themeColor);
  z-index: 1000;
  color: white;
  transition: all 0.3s;
  font-weight: 700;

}

.btn-comecar-right:hover {
  background: none;
  color: var(--themeColor);
  padding: 10px 40px;
  transform: translateX(-16px);
  border: 1px solid var(--themeColor);
}


.description {
  width: 90%;
  height: 70%;
  font-size: 0.7em;
  margin-top: 10%;
  user-select: none;
  padding: 10px;
  font-size: 15px;
  /* font-weight: 80; */
  color: rgb(40, 40, 40);
  user-select: none;
  line-height: 2;
  overflow: auto;

  transition: 0.6s
}


::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 5px;
  backdrop-filter: blur(8px) saturate(170%);
}

::-webkit-scrollbar {
  width: 21px;


}


@import '../../App.css';

::-webkit-scrollbar-thumb {
  background: var(--themeColor);
  border-radius: 2px;
}

@media screen and (min-width: 499px) {

  .nav_buttons {
    animation: goup .5s forwards;
  }


  .btn-comecar-right {}

  .section-right {
    padding-left: 0px;
  }
}


@media screen and (max-width: 600px) {

  .nav_buttons {
    animation: godown .5s forwards;
  }

  .tela-inicial-right {
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .section-left {
    display: none;

  }


}

/* //////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////// */

@media(max-width:600px) {

  .imgbg,
  .imgbg-mao-na-massa {
    display: none;

  }

  .tela-inicial-right {
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .tela-inicial-left {
    display: none;


  }

  .btn-comecar-right {
    margin: auto;
  }

  .title2 {
    margin: auto;

  }


  .main {
    width: 84%;
    display: flex;
    justify-content: center;
    align-items: center;

  }


}



@media(max-width:1300px) {

  .imgbg,
  .imgbg-mao-na-massa {
    display: none;
  }

  .section-left {
    padding: 0;
  }




}

@media(min-width:901px) {

  .imgbg,
  .imgbg-mao-na-massa {
    display: auto;
  }


}

@media(max-width:900px) {


  .section-left {
    animation: hide .5s forwards;
  }

  .section-right {
    animation: show .5s forwards;
  }

  .centro {
    z-index: -999;
    animation: opacityhide 0.1s forwards;
  }

  .centro2 {
    z-index: -999;
    animation: opacityhide 0.1s forwards;
  }

  /* .imgbg{
    animation: opacityhide 0.3s forwards;
  } */

  main {
    animation: fillspace 1s forwards;
  }

  /* .btn-comecar-left{
    animation: hidebutton 1s forwards;
  }
  .btn-comecar-right{
    animation: showbutton 1s forwards;
  } */

}

.nav_buttons_left {
  top: 46%;
  left: 6.5%;
  width: 60px;
  height: 60px;
}

.nav_buttons_right {
  top: 46%;
  right: 6.5%;
  width: 60px;
  height: 60px;
}



@media screen and (min-width: 900px) {
  .btn-comecar-right {
    display: none;
  }

  .btn-comecar-left {
    margin-left: 40px;
  }

  .tela-inicial-right {
    display: none;

  }

  .tela-inicial-left {
    display: flex;
  }

}

.tela-inicial-right {
  display: none;
}

@media screen and (max-width: 901px) {
  .btn-comecar-right {}

  .tela-inicial-right {
    height: 92%;
    display: flex;
    flex-direction: column;
    padding: 30px;
  }

  .nav_buttons_left {

    left: 4%;

  }

  .nav_buttons_right {
    right: 4%;


  }


}

@media(max-width:1600px) {
  .section-left {
    zoom: .8;
  }

  .section-right {
    zoom: .8;
  }
}

@media(min-width:1601) {
  .section-left {
    zoom: 1;
  }

  .section-right {
    zoom: 1;
  }
}


@media(min-width:900px) {

  .section-left {
    animation: reversehide 1s forwards;
  }

  .section-right {
    animation: reverseshow 1s forwards;
  }

  .centro {
    animation: opacityshow 1s forwards;
  }

  .centro2 {
    animation: opacityshow 1s forwards;
  }
}

@media(min-width:600px) {
  main {
    animation: unfillspace 1s forwards;

  }

}

@media(max-width:600px) {
  .main-mobile {
    margin-top: 70px;

  }

  .nav_buttons_left {
    animation: godown .5s forwards;
    left: 0.8%;
    width: 57px;
    height: 57px;
  }

  .nav_buttons_right {
    animation: godown .5s forwards;
    right: 0.8%;
    width: 57px;
    height: 57px;


  }

}

@media(max-height:600px) {

  .nav_buttons_left {
    margin-bottom: 19px;
  }

  .nav_buttons_right {
    margin-bottom: 19px;
  }

}