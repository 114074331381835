
.capa-name{
    width: 95%;   
    /* height:500px; */
    user-select: none;   
    font-size: 18px; 
    font-family: sans-serif;
color:rgb(40, 40, 40);
    user-select: none;   
    padding-top: 23px;
   
    text-align: justify;   
    line-height: 23px;   
    overflow: auto;

}

/* @media screen and (max-width: 1299px) {
    .capa-name{
    
        padding-right: 5px ; 
    }
} */
.course-name-title{
  font-weight: 400;
  font-size: 60px;
  display: flex;

  margin-top: 3vh;
  margin-bottom: 0px;
  color: rgba(50, 50, 50, 0.683);


}

.capa-name h1{
  font-family: 'Open Sans', sans-serif;  
  font-weight: 400;  
  white-space: pre-line; 
  line-height: 26px;

}

/* 
::-webkit-scrollbar-track {
  
  border-radius: 15px;
  backdrop-filter: blur(8px) saturate(150%);
}
::-webkit-scrollbar {
  width: 9px;
  background: 1px solid rgb(196, 196, 196);
   
}

@import '../../App.css';
::-webkit-scrollbar-thumb {
  background:var(--themeColor);
  border-radius: 6px ;
  border:2px solid rgba(200,200,200,0.5);

}
::-webkit-scrollbar-thumb:hover {
  border-radius: 6px ;
} */


::-webkit-scrollbar-track
{
	/* -webkit-box-shadow: inset 40px 10px 21px rgba(0,0,0,0.1);
	background-color: #f5f5f500;
	border-radius: 10px;
  height: 90vh; */
  margin-right: 10px;
  display: block; 
  margin-top: 4vw;
  margin-bottom: 4vw;

}

::-webkit-scrollbar
{
	width: 72px;
	background-color: rgba(0,0,0,0.0);

}


::-webkit-scrollbar-thumb
{

    background: var(--themeColor);
  border-right: 62px rgba(0, 0, 0, 0) solid;
  background-clip: padding-box;
}





.course-name-title-tc{
  /* style={{ fontWeight: '600', paddingTop: '50px ', fontSize: '70px', display: 'flex' }}> */
  /* font-weight: 600;   
  font-size: 60px;
  display: flex;
  margin-top: 20px;
  margin-bottom: 30px;
  color: rgba(50, 50, 50, 0.783); */

  font-weight: 400;
  font-size: 60px;
  display: flex;

  margin-top: 40px;
  margin-bottom: 0px;
  color: rgba(50, 50, 50, 0.683);
}




@media screen and (max-width: 650px) {
    .capa-name{
      justify-content: center;
      align-items: center;
      padding-right: 5px ; 

    }
    .course-name-title{  
      font-weight: 600;
      font-size: 40px;
      display: flex;
      white-space:nowrap;
      margin-top: 20px;
      color: rgba(50, 50, 50, 0.683);
  }
  .course-name-title-tc{
    font-size: 40px;
    flex-wrap:wrap; 
  }

}

@media screen and (max-height: 720px) {
  .capa-name h1{
  font-size: small;
  line-height: 1.6;


  }
}




    


/* @media screen and (max-width: 650px) {
    .course-name-title{
      white-space:normal;
      margin:auto;
    }
  } */


/* @media screen and (max-width: 650px) {
    .course-name-title-tc{
      font-size: 8vw;
      margin:auto;
 
    }
    .tile{
      margin:auto;
      margin-bottom: 30px;
    }
  } */
  @media screen and (max-width: 600px) {
    
    .course-name-title{      
      margin-top: 120px;
    
  }
  .course-name-title-tc{

    margin-top: 120px;
    font-size: 35px;
  }
  ::-webkit-scrollbar
{
	width: 35px;
	background-color: rgba(0,0,0,0.0);
}


::-webkit-scrollbar-thumb
{

    background: var(--themeColor);
  border-right: 25px rgba(0, 0, 0, 0) solid;
  background-clip: padding-box;
  
}


}
  

