/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap'); */
.content-questions{
    width: 100%;
    padding-top:53px;
    padding-left:10px;
    height:25vh;
    /* font-weight: 80; */

}
/* MAO NA MASSA */
.question-name{
    
    width: 90%;
    margin-top: 20px;
    min-height:80px;
    /* user-select: none;       */
color:rgb(40, 40, 40);
    /* user-select: none; */
    margin-left: 30px; 
    padding-right: 20px; 
    min-height: 80px; 
    /* adicionado altura minima  <<<<<<<<<<<<<*/
    text-align: justify;

}
.question-name p{
    /* font-weight: 430;   */
    /* font: normal normal normal 18.4px/25px Open Sans;    */
    color:rgb(40, 40, 40); 
    letter-spacing:0;
    text-align: justify;
}


.question-name-container{
    display: flex;
    justify-content: center;

}
.question-name img{
    /* style="width: 90vw; text-align: center; border-radius: 15px; box-shadow: 0px 0px 20px 1px rgba(28, 28, 28, 0.4);" */
    /* width: 90vw; */
    border-radius: 6px;
    box-shadow: 0px 0px 15px 1px rgba(20, 20, 20, 0.4);
    max-width:100%;
    height:auto;
    
    display:block;
    margin: auto;
    margin-top:30px;
    
}

.question-name img[src*="pequena"] {
    vertical-align: bottom; 
    display:inline-block;
    margin-top:0px;
    border-radius: 4px;

    box-shadow: 0px 0px 15px 1px rgba(20, 20, 20, 0.123);

  }

/* TESTE CONHECIMENTOS */
.question-name-tc{
    width: 90%;
    margin-top: 20px;
    min-height:80px;
    /* user-select: none;       */
color:rgb(40, 40, 40);
    /* user-select: none; */
    margin-left: 30px; 
    padding-right: 20px; 
    min-height: 80px; 
    /* adicionado altura minima  <<<<<<<<<<<<<*/
    text-align: left;

}
.question-name-tc p{
    /* font-weight: 430;   */
    /* font: normal normal normal 18.4px/25px Open Sans;    */
    color:rgb(40, 40, 40); 
    letter-spacing:0;
    
}
.question-name-tc-container{
    display: flex;
    justify-content: center;

}
.question-name-tc img{
    /* style="width: 90vw; text-align: center; border-radius: 15px; box-shadow: 0px 0px 20px 1px rgba(28, 28, 28, 0.4);" */
    margin-top:0px;
    /* width: 90vw; */
    border-radius: 3px;

    box-shadow: 0px 0px 15px 1px rgba(28, 28, 28, 0.1);
   max-width:100%;
    height:auto;
    vertical-align: middle; 

    /* display: block;
    margin-left: auto;
    margin-right: auto; */

}





.alternatives-container{
    /* font-weight: 80; */
    width: 100%;
    font-size: 1em;
    /* font: normal normal normal 22px/25px Open Sans;    */
    color:rgb(40, 40, 40);
}
.alternatives-container-other{
    text-transform: uppercase;
}

.img_container{
    /* width: 58vh;
    height: 58vw; */

}

.imagem_conteudo{
    /* style={{ textAlign: 'center', borderRadius: '15px', boxShadow: '0px 0px 20px 1px rgba(28, 28, 28, 0.4)' }}  */

    max-width: 80vw;
    max-height: 80vh;
    border-radius: 15px;
    box-shadow: 0px 0px 14px 1px rgba(255, 237, 237, 0.4);

}

.mysqldata img{


}
