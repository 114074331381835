* {
  margin: 0;
  padding: 0;


}

@import '../../App.css';

@import url('https://fonts.googleapis.com/css2?family=McLaren&display=swap');

.Genially {
  box-sizing: border-box;
  text-align: center;
  /* overflow: hidden; */
  max-width: 100vw;
  max-height: 100vh;
}


.genially-embed-new {
  width: 1000%;


  font-family: "Source Sans Pro", sans-serif;
}

.txt_modal_educador{
  font-family: 'McLaren', cursive;
  text-align: center;
  font-size: 1em;
}





.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.form input {
  width: 300px;
  height: 60px;
  margin: 10px;
  font-size: 25px;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 400px;
  height: 890px;
  border: 2px solid black;
  border-radius: 15px;
  margin: 10px;

}

.imageBox {
  padding: 2px;
  display: flex;
  align-items: start;
  justify-content: space-around;
}

.title {

  position: absolute;

  padding-top: 5px;
  left: 0;

  text-align: center;
  z-index: 99;
  background-color: rgba(145, 165, 223, 0.363);
  padding-bottom: 4px;
  padding-left: 30px;
  padding-right: 30px;

}

.over {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  padding-top: 30px;
  left: 0;
  right: 0;
  text-align: center;
}


.content {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  padding-top: 130px;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 20px;
}



#updateInput {
  width: 100px;
  height: 30px;
}





.questions {
  /* border:2px solid red; */
  font-size: 2em;
  text-shadow: 1px 1px 5px white;
}

.alternatives {
  /* border:2px solid green; */
  min-height: 36vh;
  padding: 35px
}

.btn-fullscreen-genially {
  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(0, 0, 60, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.35);
  border-radius: 50%;
  width: 45px;
  height: 45px;
  position: absolute;
  left: 5px;
  bottom: 5px;
  z-index: 1100;
}

@media screen and (max-width:600px) {
  .btn-fullscreen-genially {
    display: none;
  }
}





/* @media screen and (max-width: 520px) {
  .content { 
    margin-top: 150px;
  }
  .questions{  
    font-size:1em;
  }
  .alternatives{
    padding:0;
  }
  .alternatives-container{
    max-width:100%;
    margin:auto;
    padding:10px 5px; 

  }

} */


/* //////////////////////////////////////////// MODAL //////////////////////////////////////////// */
.modal-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;
  height: 360px;
  border-radius: 12%;
  /* background: #008DA9 ; */
  background: var(--backGroundColor);

  padding: 0 20px;
  z-index: 5;
  box-shadow: 4px 4px 20px rgba(255, 255, 255, .5), 4px 4px 20px rgba(0, 0, 0, 0.55);
  z-index: 9999999999;
}

.modal-container img {
  width: 100%;
  display: block;
  flex: 4.5;
}

.modal-texto {
  text-align: center;
  width: 100%;
  flex: 2.5;
  display: block;
  color: white;
  /* color: var(--themeColor); */
  font-weight: 400;
  font-size: 25px;
}

.modal-btn-sim,
.modal-btn-nao {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  width: 40%;
  padding: 5px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  /* border: 2px solid #BAE10E; */
  border: 2px solid var(--themeColor);

  filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.25));
  /* color: var(--themeColor); */
  color: white;
  cursor: pointer;
  transition: all 0.3s;

}

.modal-btn-sim:hover,
.modal-btn-nao:hover {
  /* background: #BAE10E; */
  background-color: var(--backGroundColor);
  filter: brightness(130%)
    /* color: black; */

}

/* //////////////////////////////////////////// MODAL //////////////////////////////////////////// */