.embed-capa-master{
  pointer-events: none; 
  z-index: 100;
  overflow: hidden; 
  position: absolute; 
  right: 0; 
  width: 70vw; 
  height: 100vh;
}
@media screen and (max-width:2100px){
  .embed-capa-master{
    right: 9%;  
    width: 50vw; 
   
  }

}
@media screen and (max-width:1700px){
  .embed-capa-master{
    right: 9%;
  
    width: 50vw; 
   
  }

}