
.enunciado{
    height: 90vh;
    width: 100%;
    padding-top: 30px;
    color: rgb(40, 40, 40);
}

.enunciado textarea{
    overflow: auto;
    border: none;
    border-radius: 30px;
    width: 100vw;
    height: 45vh;
    resize: none;
    color: rgb(40, 40, 40);
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 35px;
    font-size: 1em;
    background-color: rgba(0, 0, 0, 0.04);
    /* font-family: 'Open Sans', sans-serif !important; */
    height: 52vh;
}

.enunciado h2{
    font-size: 2em;
    font-weight: 300;
    color: rgb(20, 20, 20);
        font-family: 'Open Sans', sans-serif !important;


}

.areaTexto{
    overflow: auto;
    border: none;
    border-radius: 30px;
    width: 100vw;
    height: 55vh;
    resize: none;
 
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 1.3em;
    background-color: rgba(0, 0, 0, 0.05);
    /* font-family: 'Open Sans', sans-serif !important; */
    color: rgb(40, 40, 40);
}


