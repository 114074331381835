@import url('https://fonts.googleapis.com/css2?family=McLaren&display=swap');

.capa-horaDoLivro{
  height:100%;
  display:'flex'; 
  flex-direction:"column"; 
  /* background-color: rgba(0, 130, 0, 0.23); */
  padding: 10% 10%;
  /* backdrop-filter: blur(1px);       */
} 
@media screen and (max-width:600px){
  .capa-horaDoLivro{
    
    justify-content:'center'; 
    align-items:"center";
  } 
} 


.title-tcEnsinaMais{
  font-family: 'McLaren', cursive;
  font-height: 200;
  font-size: 4rem;
  color: #28282A;
  margin-bottom: -10px;

}

.texto-capaEnsinaMais{
  font-family: 'McLaren', cursive;
  font-height: 200;
  font-size: 1.8rem;
  max-width: 50%;
  color: #28282A;
    line-height: 1.2;
    min-width: 400px;
}

.btn-comecarEnsinaMais{

  margin-top: 40px;

  align-items: center;
  font-size: 22px;
  padding: 0px 35px;
  border-radius: 50px;
  left: 10%;
  bottom: 10%;
  background: var(--themeColor);
  background: var(--backGroundColor);
  border: 0px solid var(--themeColor);
  z-index: 1000;
  color: white;
  transition: all 0.3s;
  bottom: 100px;
  font-weight: 700;
  /* width: 200px; */
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;

}