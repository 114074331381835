.audio-player {
    display: flex;
    align-items: center;
    gap: 10px;
    background: #f0f0f0;
    border-radius: 10px;
    padding: 10px;
    position: absolute;
    
    bottom: 30px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 80%;
    
    align-content: center;
    
    & svg {
        cursor: pointer;
        width: 36px;
        height: 36px;
        align-content: center;
    }
    
    & svg:hover {
        opacity: 0.7;
    }
    & button {
        cursor: pointer;
        width: 86px;
        height: 56px;
        border-radius: 10px;
    }
    
}

.progress-bar {
    width: 100%;
    margin: 0 10px;
}

.audio-time {
    font-size: 12px;
    color: #555;
}

.speed-selector {
    border-radius: 5px;
    padding: 5px;
}