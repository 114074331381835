@import '../../App.css';

.title2{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;    
    /* height:44px; */
    border:2px solid var(--backGroundColor) ;
    border-radius: 30px;  
    padding-top: 6px;
    padding-bottom: 6px;
    max-width: fit-content;
    color: var(--backGroundColor);
    width:35vw; 
   /* font-weight: 500; */


    
/*     
    text-shadow: 1px 1px 1px var(--themeColor); */
    padding-left: 18px;
    padding-right: 18px;

}
@media screen and (max-width: 1470px) {
  .title2{
    font-size: 14px;
    padding-left: 25px;
    padding-right: 25px;  
    /* height:44px; */
    width:40vw;   
    
  }
}

@media screen and (max-width: 890px) {
  .title2{
    font-size: 14px;
    padding-left: 25px;
    padding-right: 25px;  
    /* height:44px; */
    width:100vw;   
  }
}

@media screen and (max-width: 600px) {
 .title2{  
    position: absolute; 
    left:7.5%;
    width: 85%;
    top:60px;    
    min-height:42px;
  }
  .title2 div{
    font-size: 14px; 
  }

}
