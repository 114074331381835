.teste-conhecimentos{
    box-sizing: border-box;
    min-width:100%;
    /* max-width: 100%; */
    min-height: 100vh;  
    display:flex;
    justify-content: center;
    align-items: center; 
    background-repeat: no-repeat; 
    background-size: cover; 
    z-index: 1;
    /* background-image: url('bg.jpg');  */
    transition: 0.6s;
    /* overflow: hidden; */
    /* background-image: url('https://img.genial.ly/5fc6cd5b3166f50f91c09b4c/a9adac76-0b67-4862-a9a8-83b50b60f987.jpeg') */
}

@import '../../App.css';

.-left{
    
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    background: rgba(255, 255, 255, 0.95);
    font-size: 2em;
    /* overflow: hidden; */
    z-index: 0;
    transition: 0.6s;
    /* backdrop-filter: blur(15px) saturate(140%); */
}  
.tela-final{
    width:87%;
    margin:auto;
    min-height:800px;  
    display:flex;
    flex-direction: column;
    overflow: hidden;

}
.tela-final-mensagem{
    margin-top: 75px;
    font-weight:400px;
    color: rgb(40, 40, 40);


}
.tela-final button{

  margin-top: 50px;
  font-size: 20px; 
  padding:7px 20px;
  cursor:pointer;
  background:var(--themeColor); 
  border-radius: 30px;
  color:white;
}
.btn-fullscreen{
  display:flex;
  justify-content:center;
  align-items:center;
  border:2px solid rgba(210,210,210,0.35);
  border-radius:50%;
  width:60px;
  height:60px;
  position: absolute;
  left: 50px;
  bottom: 40px;
}
@media screen and (max-width:1300px){
  .btn-comecar-left{
    margin-left:40px;
    
  }

}
@media screen and (max-width:600px){
    .tela-final{
        align-items: center;
    }
    .tela-final button{
        margin-top: 75px;
        margin-left: 0;
        
    }
    .btn-fullscreen{
      display: none;
    }
    /* .nav_buttons_left{
      animation: godown .5s forwards;
      left:0.8%;
      width: 57px;
      height: 57px;
    }
    .nav_buttons_right{
      animation: godown .5s forwards;
      right: 0.8%;
      width: 57px;
      height: 57px;
    
    } */

}
