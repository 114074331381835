.title{
    position: absolute;
    top:16px;
    margin-left: 7.5%;
    display: flex;
    justify-content: left;
    align-items: left;
    font-size: 17px;
    /* font-weight: 500; */

    border:2px solid white ;
    border-radius: 30px;
    padding: 0 12px;
    max-width: fit-content;
    color: white; 


    
}

@media screen and (max-height: 400px){
  .title{
    zoom: 0.66;
    
  }
} 

@media screen and (max-height: 728px){
  .title{
    zoom: 0.66;


  }
}


  @media screen and (max-width: 800px) {
    .title{
      zoom: .9;
      font-size: 14px;
        top:20px;
        width:80%;
        display: flex;
        align-items: left;
        justify-content: left;  

    }
  }