@import url('https://fonts.cdnfonts.com/css/product-sans');

.teste-conhecimentos-ensina-mais  {
  font-family: 'Product Sans', sans-serif;
  font-size: 1.5em;

    box-sizing: border-box;
    min-width:100%;
    /* max-width: 100%; */
    min-height: 100vh;  
    /* display:flex; */
    justify-content: center;
    align-items: center; 
    background-repeat: no-repeat; 
    background-size: cover; 
    z-index: 1;

    /* background-image: url('bg.jpg');  */
    transition: 0.6s;
 
    /* overflow: hidden; */
    /* background-image: url('https://img.genial.ly/5fc6cd5b3166f50f91c09b4c/a9adac76-0b67-4862-a9a8-83b50b60f987.jpeg') */
}

@import '../../App.css';

.-left{
    
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    background: rgba(255, 255, 255, 0.95);
    font-size: 2em;
    /* overflow: hidden; */
    z-index: 0;
    transition: 0.6s;
    /* backdrop-filter: blur(15px) saturate(140%); */
}  
.tela-finalEnsinaMais{
    width:87%;
    margin:auto;
    /* min-height:800px;   */
    display:flex;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
    display: flex;
    flex-direction: column;

}
.tela-finalEnsinaMais-mensagem{
  font-family: 'Product Sans', sans-serif;
  background: rgba(255, 255, 255, 0.8);
  font-size: 2.6vmin;
    font-weight:800;
    color: rgb(40, 40, 40);
    /* border: 1px solid black; */
    padding-bottom: 4vh;
    padding-top: 4vh;
    margin-bottom: 10vh;
    margin-top: 10vh;
    padding-left: 5vw;
    padding-right: 5vw;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.tela-finalEnsinaMais button{

    /* margin-top: 50px;
    font-size: 20px; 
    padding:7px 20px;
    cursor:pointer;
    background:var(--themeColor); 
    border-radius: 30px;
    color:white; */

    align-items: center;
    font-size: 22px;
    padding: 12px 25px;
    border-radius: 30px;
    left: 10%;
    bottom: 10%;
    background: var(--themeColor);
    border: 1px solid var(--themeColor);
    z-index: 1000;
    color: white;
    transition: all 0.3s;
    bottom: 100px;
    font-weight: 700;
    margin-top: 30px;
 
}

.tela-finalEnsinaMais button:hover {
  background: none;
  color: var(--themeColor);
  padding: 7px 40px;
  /* transform: translateX(-16px); */
  border: 1px solid var(--themeColor);
}

.tela-finalEnsinaMais img{
  /* width: 100%; */
  width: 50vw
}

.btn-fullscreen{
  display:flex;
  justify-content:center;
  align-items:center;
  border:2px solid rgba(210,210,210,0.35);
  border-radius:50%;
  width:60px;
  height:60px;
  position: absolute;
  left: 50px;
  bottom: 40px;
}
@media screen and (max-width:1300px){
  .btn-comecar-left{
    margin-left:40px;
    
  }

}
@media screen and (max-width:600px){
    .tela-finalEnsinaMais{
        align-items: center;
    }
    .tela-finalEnsinaMais button{
        margin-top: 75px;
        margin-left: 0;
        
    }
    .btn-fullscreen{
      display: none;
    }
    /* .nav_buttons_left{
      animation: godown .5s forwards;
      left:0.8%;
      width: 57px;
      height: 57px;
    }
    .nav_buttons_right{
      animation: godown .5s forwards;
      right: 0.8%;
      width: 57px;
      height: 57px;
    
    } */

}
