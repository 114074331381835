@import url('https://fonts.googleapis.com/css2?family=Spectral+SC:ital,wght@1,600&display=swap');

.container_atividade_duploEnsinaMais{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 50px;
  padding-top: 65px;
  /* zoom: 0.7; */
}

.container_atividade_simplesEnsinaMais{
  border:2px solid #000;
  background-color:#FDF1D8;
  width:48%;
  /* margin:auto; */
  padding:10px;
  border-radius: 4px;
  /* padding-top: 20px; */
  /* height:90%; */
  /* margin-top:; */
  /* min-height: 460px; */
  height: 460px;
  position:relative;
  /* -top: 20px; */
  display: flex;
  box-shadow: 10px 10px 1px 0px rgba(0,0,0,0.3);
  }

  .container_embed{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 3px solid #000; */
    margin: auto;
    /* overflow: hidden; */


  }

  .container_embed img{
    /* width: 100%; */
    /* height: 100%; */
    object-fit: cover;
    cursor: default;
    border-radius: 6px;
    border: 2px solid #000;
    max-height: 100%;
    margin-bottom: 10px;
    /* object-fit: cover; */
  }

  .container_embed:hover{
    overflow: visible;
  }


  .container_perguntas{
    /* width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 3px solid #000; */
    
    padding-top: 24px;
  }




.container_title{

}


.pergunta{
    font-family: 'Product Sans', sans-serif ;
    font-size: 2.3em;
    font-size: 3.2vmin;
    white-space: pre-line;
    border: 2px solid #000;
    background-color: #F7CA6B;
    margin: auto;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 20px; 
    padding-right: 20px;
    max-width: 900px; 
    box-shadow: 10px 10px 1px 0px rgba(0,0,0,0.3);
}

.pergunta p{
  font-family: 'Product Sans', sans-serif ;
  font-size: 3.2vmin;
}

.caixa_som_pergunta{
    /* color: #F7CA6B; */
    /* margin-top: 10%;
    margin-bottom: 10px;
    width: 100px; */
    /* border: 2px solid #000;
    background-color: #000; */
    min-width: 50px;
    /* width: 100px; */
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;

}

.caixa_som_pergunta svg{
  margin-top: 0px;
  /* margin-bottom: 10px; */
  /* width: 100px;
  height: 100px; */
  width: 50px;
  /* position: fixed;
  bottom: 0; */
  box-shadow: 10px 10px 1px 0px rgba(0,0,0,0.3);
  border-radius: 0 4px 4px 0;
  cursor: pointer;

  /* position: relative;
  cursor: pointer; */
}






p{
  animation : none;
  animation-delay : 0;
  animation-direction : normal;
  animation-duration : 0;
  animation-fill-mode : none;
  animation-iteration-count : 1;
  animation-name : none;
  animation-play-state : running;
  animation-timing-function : ease;
  backface-visibility : visible;
  background : 0;
  background-attachment : scroll;
  background-clip : border-box;
  background-color : transparent;
  background-image : none;
  background-origin : padding-box;
  background-position : 0 0;
  background-position-x : 0;
  background-position-y : 0;
  background-repeat : repeat;
  background-size : auto auto;
  border : 0;
  border-style : none;
  border-width : medium;
  border-color : inherit;
  border-bottom : 0;
  border-bottom-color : inherit;
  border-bottom-left-radius : 0;
  border-bottom-right-radius : 0;
  border-bottom-style : none;
  border-bottom-width : medium;
  border-collapse : separate;
  border-image : none;
  border-left : 0;
  border-left-color : inherit;
  border-left-style : none;
  border-left-width : medium;
  border-radius : 0;
  border-right : 0;
  border-right-color : inherit;
  border-right-style : none;
  border-right-width : medium;
  border-spacing : 0;
  border-top : 0;
  border-top-color : inherit;
  border-top-left-radius : 0;
  border-top-right-radius : 0;
  border-top-style : none;
  border-top-width : medium;
  bottom : auto;
  box-shadow : none;
  box-sizing : content-box;
  caption-side : top;
  clear : none;
  clip : auto;
  color : inherit;
  columns : auto;
  column-count : auto;
  column-fill : balance;
  column-gap : normal;
  column-rule : medium none currentColor;
  column-rule-color : currentColor;
  column-rule-style : none;
  column-rule-width : none;
  column-span : 1;
  column-width : auto;
  content : normal;
  counter-increment : none;
  counter-reset : none;
  cursor : auto;
  direction : ltr;
  display : inline;
  empty-cells : show;
  float : none;
  font : normal;
  font-family : inherit;
  font-size : medium;
  font-style : normal;
  font-variant : normal;
  font-weight : normal;
  height : auto;
  hyphens : none;
  left : auto;
  letter-spacing : normal;
  line-height : normal;
  list-style : none;
  list-style-image : none;
  list-style-position : outside;
  list-style-type : disc;
  margin : 0;
  margin-bottom : 0;
  margin-left : 0;
  margin-right : 0;
  margin-top : 0;
  max-height : none;
  max-width : none;
  min-height : 0;
  min-width : 0;
  opacity : 1;
  orphans : 0;
  outline : 0;
  outline-color : invert;
  outline-style : none;
  outline-width : medium;
  overflow : visible;
  overflow-x : visible;
  overflow-y : visible;
  padding : 0;
  padding-bottom : 0;
  padding-left : 0;
  padding-right : 0;
  padding-top : 0;
  page-break-after : auto;
  page-break-before : auto;
  page-break-inside : auto;
  perspective : none;
  perspective-origin : 50% 50%;
  position : static;
  /* May need to alter quotes for different locales (e.g fr) */
  quotes : '\201C' '\201D' '\2018' '\2019';
  right : auto;
  tab-size : 8;
  table-layout : auto;
  text-align : inherit;
  text-align-last : auto;
  text-decoration : none;
  text-decoration-color : inherit;
  text-decoration-line : none;
  text-decoration-style : solid;
  text-indent : 0;
  text-shadow : none;
  text-transform : none;
  top : auto;
  transform : none;
  transform-style : flat;
  transition : none;
  transition-delay : 0s;
  transition-duration : 0s;
  transition-property : none;
  transition-timing-function : ease;
  unicode-bidi : normal;
  vertical-align : baseline;
  visibility : visible;
  white-space : normal;
  widows : 0;
  width : auto;
  word-spacing : normal;
  z-index : auto;
  /* basic modern patch */
  all: initial;
  all: unset;
}


@media  (max-width: 1400px){

  .container_atividade_duploEnsinaMais{
    zoom: 0.9;
  }

  
  .container_atividade_simplesEnsinaMais{
    /* background-color: #F7CA6B; */
    /* min-height: 360px; */

    /* height: 500px; */

  }
}

@media  (max-width: 1200px){

  .container_atividade_duploEnsinaMais{
    zoom: 0.85;
  }
  
  .container_atividade_simplesEnsinaMais{
    /* background-color: #F7CA6B; */
    /* min-height: 360px; */
    height: 400px;

  }
}




@media  (max-width: 1000px){

  .container_atividade_duploEnsinaMais{
    zoom: 0.8;
  }
  
  .container_atividade_simplesEnsinaMais{
    /* background-color: #F7CA6B; */
    /* min-height: 360px; */
    height: 360px;

  }
}

