@import '../../App.css';

@import url('https://fonts.cdnfonts.com/css/aller-display');

.titleEnsinaMaisCapa{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;    
    /* height:44px; */
    /* border:2px solid var(--backGroundColor) ; */
    border-radius: 30px;  
    padding-top: 6px;
    padding-bottom: 6px;
    max-width: fit-content;
    /* color: var(--backGroundColor); */
    width:35vw; 
   font-weight: 700;


}