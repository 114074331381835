/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap'); */
.objetiva {
    user-select: none;
    cursor: pointer;
    transition: 0.3s;

}

.alternative-name {
    font-family: 'Open Sans', sans-serif;
    padding-left: 1vw;
    padding-right: 1vw;
    text-align: left;

    font-weight: 500;
    color: rgb(40, 40, 40);
    user-select: none;
    cursor: pointer;
    float: right;

}


.alternatives-container {
    width: 100%;
    /* font-size: 0.6em; */
    padding-right: 80px;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
}

.alternatives-container-other {
    width: 90%;
    text-transform: uppercase;
    /* display: flex; */
    /* font-size: 0.6em; */

}

.alternative-box {
    display: flex;
    text-align: center;
    align-items: center;
    padding-top: 7px;
    padding-bottom: 7px;
    border-bottom-right-radius: 35px;
    border-top-right-radius: 35px;
    border: 1px solid #f7316213;
    margin-left: 20px;
    padding-left: 20px;
    transition: 0.25s;

}



@import '../../../App.css';

.alternative-box:hover {
    border: 3px solid var(--themeColor);
    border-bottom-right-radius: 35px;
    border-top-right-radius: 35px;
    cursor: pointer;
    transition: 0.3s;
}

.alternative-box:hover .circle p {
    color: var(--themeColor);
    font-weight: 600;
}

.circle {
    display: inline-block;
    border-radius: 50%;
    min-width: 30px;
    min-height: 30px;
    width: 30px;
    height: 30px;

    align-items: center;
    display: flex;
    justify-content: center;
    display: flex;
    box-sizing: content-box;
    white-space: nowrap;
    padding: 10px;
    transition: all 0.3s;
    border: 1px solid var(--themeColor);
}

.circlefilled {
    display: inline-block;
    border-radius: 50%;
    min-width: 30px;
    min-height: 30px;
    width: 30px;
    height: 30px;
    border: 1px solid var(--themeColor);
    background-color: var(--themeColor);
    align-items: center;
    display: flex;
    justify-content: center;
    display: flex;
    box-sizing: content-box;
    white-space: nowrap;
    padding: 10px;

}

.circle:before {
    content: "";
    display: inline-block;

    vertical-align: middle;
    padding-top: 100%;
    height: 0;
}

.circle span {
    display: inline-block;
    vertical-align: middle;
}

.circle p {
    transition: all 1s;
    color: gray;
    font-size: 20px;
}


/* 
.box-resposta {
    display: inline-block;
    border-radius: 50%;
    min-width: 30px;
    min-height: 30px;
    width: 30px;
    height: 30px;
    border: 1px solid var(--themeColor);
    background-color: var(--themeColor);
    align-items: center;
    display: flex;
    justify-content: center;
    display: flex;
    box-sizing: content-box;
    white-space: nowrap;
    padding: 10px;
} */






@media(max-height:700px) {
    .alternative-box {
        animation: shriwk 2s forwards;
        zoom: .8;
    }

    .alternatives-container {

        padding-left: 50px;
        padding-bottom: 70px;
    }


}

@media(min-height:701px) {
    .alternative-box {
        animation: unshriwk 2s forwards;

    }

    .alternatives-container {

        padding-left: 0px;

    }



}

@keyframes shriwk {
    0% {
        margin-top: 20px;
        width: 100%;
    }

    100% {
        margin-top: 5px;
        width: 100%;
        padding-bottom: 10px;
    }
}


@keyframes unshriwk {
    0% {
        margin-top: 5px;
        width: 100%
    }

    100% {
        margin-top: 20px;
        width: 100%;
        zoom: 1
    }
}