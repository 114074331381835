.container-tipos_questoes{
 
  min-height:40px;
  display: flex;
  font-weight: 400;
  opacity: 0.8;
  margin:auto;
  line-height: 0.9;
  text-align: center;
  font-size: 3em;
  color: rgba(50, 50, 50, 0.783);
  margin-bottom: 20px;
  white-space: nowrap;
  font-size: 2.8vw;
}