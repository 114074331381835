/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap'); */


@import url('https://fonts.googleapis.com/css2?family=Spectral+SC:ital,wght@1,600&display=swap');
@import url('https://fonts.cdnfonts.com/css/aller-display');

.conteudo-ensina-mais{
    user-select: none;
    transition: 0.3s;
    font-family: 'Product Sans', sans-serif !important;
    padding-top: 14vh;
}

.objetivaEnsinaMais {
    user-select: none;
    cursor: pointer;
    transition: 0.3s;
    font-family: 'Product Sans', sans-serif;
    padding-right: 30px;

}

.alternative-nameEnsinaMais {
    font-family: 'Product Sans', sans-serif;
    font-size: 1.3em;


    padding-left: 1vw;
    padding-right: 1vw;
    text-align: left;

    font-weight: 500;
    color: rgb(40, 40, 40);
    user-select: none;
    cursor: pointer;
    float: right;
    

}


.alternatives-container {
    width: 100%;
    /* font-size: 0.6em; */
    padding-right: 80px;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    

}

.alternatives-container-other {
    width: 90%;
    text-transform: uppercase;
    /* display: flex; */
    /* font-size: 0.6em; */

}

.alternative-boxEnsinaMais{
    position: relative;
    display: flex;
    text-align: center;
    align-items: center;
    /* padding-top: 7px;
    padding-bottom: 7px; */
    border-radius: 35px;

    border: 1px solid #f7316213;
    margin-left: 20px;
    /* padding-left: 20px; */
    transition: 0.25s;
    margin-right: 10px;

    /* background-color: #38B6FF; */
    background-color: var(--themeColor);
    margin-bottom: 10px;

}



@import '../../../App.css';

.alternative-boxEnsinaMais:hover {
    /* border: 3px solid var(--themeColor); */
    border-bottom-right-radius: 35px;
    border-top-right-radius: 35px;
    cursor: pointer;
    transition: 0.3s;
}

.alternative-boxEnsinaMais:hover .circle_ensina_mais p {
    /* color: var(--themeColor); */
    font-weight: 600;
}

.circle_audio {
    font-family: 'Aller Display', sans-serif !important;
    display: inline-block;
    border-radius: 50%;
    min-width: 30px;
    min-height: 30px;
    width: 30px;
    height: 30px;

    align-items: center;
    display: flex;
    justify-content: center;
    display: flex;
    box-sizing: content-box;
    white-space: nowrap;
    padding: 10px;
    transition: all 0.3s;
    color: white;
    box-shadow: 0px 2px 1px 1px rgba(0,0,0,0.1);
    position: absolute;
    right: 0;
    background-color: var(--themeColor);
  
}

.circle_ensina_mais {
    font-family: 'Aller Display', sans-serif !important;
    display: inline-block;
    border-radius: 50%;
    min-width: 30px;
    min-height: 30px;
    width: 30px;
    height: 30px;

    align-items: center;
    display: flex;
    justify-content: center;
    display: flex;
    box-sizing: content-box;
    white-space: nowrap;
    padding: 10px;
    transition: all 0.3s;
    color: white;
    box-shadow: 2px 0px 1px 1px rgba(0,0,0,0.1);
}

.circlefilled {
    
    display: inline-block;
    border-radius: 50%;
    min-width: 30px;
    min-height: 30px;
    width: 30px;
    height: 30px;
    border: 1px solid var(--themeColor);
    background-color: var(--themeColor);
    align-items: center;
    display: flex;
    justify-content: center;
    display: flex;
    box-sizing: content-box;
    white-space: nowrap;
    padding: 10px;
    
}

.circle_ensina_mais:before {
    content: "";
    display: inline-block;

    vertical-align: middle;
    padding-top: 100%;
    height: 0;
    
}

.circle_ensina_mais span {
    display: inline-block;
    vertical-align: middle;
    
}

.circle_ensina_mais p {
    transition: all 1s;
    color: gray;

    font-size: 2em;
    color: white;
}


/* 
.box-resposta {
    display: inline-block;
    border-radius: 50%;
    min-width: 30px;
    min-height: 30px;
    width: 30px;
    height: 30px;
    border: 1px solid var(--themeColor);
    background-color: var(--themeColor);
    align-items: center;
    display: flex;
    justify-content: center;
    display: flex;
    box-sizing: content-box;
    white-space: nowrap;
    padding: 10px;
} */






/* @media(max-height:700px) {
    .alternative-boxEnsinaMais{
        animation: shriwk 2s forwards;
        zoom: .8;
    }

    .alternatives-container {

        padding-left: 50px;
        padding-bottom: 70px;
    }


}

@media(min-height:701px) {
    .alternative-boxEnsinaMais{
        animation: unshriwk 2s forwards;

    }

    .alternatives-container {

        padding-left: 0px;

    }



}

@keyframes shriwk {
    0% {
        margin-top: 20px;
        width: 100%;
    }

    100% {
        margin-top: 5px;
        width: 100%;
        padding-bottom: 10px;
    }
}


@keyframes unshriwk {
    0% {
        margin-top: 5px;
        width: 100%
    }

    100% {
        margin-top: 20px;
        width: 100%;
        zoom: 1
    }
} */